import { Component } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class MaintenanceComponent {}
