import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap } from '@angular/router'
import { Subscription } from 'rxjs'

import { environment } from '~env'

import { NotFoundComponent } from '../not-found/not-found.component'

@Component({
  selector: 'app-facebook-deletion',
  templateUrl: './facebook-deletion.component.html',
  styleUrls: ['./facebook-deletion.component.scss'],
  standalone: true,
  imports: [NotFoundComponent],
})
export class FacebookDeletionComponent implements OnInit {
  title: string
  deleted = false
  notFound = false
  private routeSubscription = Subscription.EMPTY

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.routeSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      const id = params.get('id')
      this.httpClient.get<{ deleted: boolean }>(`${environment.apiEndpoint}/facebook-deletion/${id}`).subscribe(
        (response) => (this.deleted = response.deleted),
        (err) => (this.notFound = true),
      )
    })
  }
}
