import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { SnackbarService } from '~core/services/ui/snackbar.service'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private snackbarService: SnackbarService) {}

  /**
   * Show a notification when user is offline
   *
   * @param error
   */
  clientError(error: Error): void {
    if (!navigator.onLine) {
      this.snackbarService.open('No Internet Connection')
    }
  }

  /**
   * Print a notification when an http error happens
   *
   * @param error
   */
  serverError(error: HttpErrorResponse): void {
    const errorMessages = error.error

    let title = 'Error!'
    let message = ''
    switch (error.status) {
      case 401:
        break
      case 403:
        this.snackbarService.open(error.statusText)
        break
      case 422:
      default:
        if (errorMessages?.errors && Object.getOwnPropertyNames(errorMessages?.errors).length > 0) {
          message = Object.values(errorMessages.errors).join(', ')
        } else {
          message = error.error.message
        }
        this.snackbarService.error(message)
        break
    }
  }
}
