import { NgIf, NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component, TemplateRef } from '@angular/core'
import { MatChipsModule } from '@angular/material/chips'
import { MatOptionModule } from '@angular/material/core'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { IconFill, Icon } from 'src/app/common/icons'
import { NavigationBarItem } from 'src/app/common/layout/navbar/navbar.component'
import { NavbarComponent } from 'src/app/common/layout/navbar/navbar.component'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'
import { AlertComponent } from 'src/app/common/ui/alert/alert.component'
import { BoxComponent } from 'src/app/common/ui/box/box.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { DialogComponent } from 'src/app/common/ui/dialog/dialog.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'

import { SearchBarComponent } from '../../../../common/components/filters/search-bar/search-bar.component'

@Component({
  selector: 'design-library',
  templateUrl: './design-library.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ViewComponent,
    ViewHeaderComponent,
    CardComponent,
    ButtonComponent,
    AlertComponent,
    MatTableModule,
    MatListModule,
    NgIf,
    NavbarComponent,
    BoxComponent,
    MatChipsModule,
    DialogComponent,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatRadioModule,
    TextComponent,
    MatTabsModule,
    NgFor,
    SearchBarComponent,
  ],
})
export class DesignLibraryComponent {
  dialogRef: any
  infobox = {
    title: 'Title',
    description: 'Description',
    type: 'Type',
    hint: 'Hint',
  }
  lotsOfTabs = new Array(30).fill(0).map((_, index) => `Tab ${index}`)
  nabvarItems: NavigationBarItem[] = [
    { label: 'Default', path: null },
    {
      label: 'Active',
      path: '/admin/design-library',
    },
    { label: 'Home', path: '/' },
    { label: 'User Account', path: '/user' },
  ]
  protected Icons = Icon
  protected IconsFill = IconFill

  constructor(
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
  ) {}

  openDialog(dialog: TemplateRef<HTMLElement>) {
    this.dialogService.open(dialog, { maxWidth: 600 })
  }

  openSnackbar() {
    this.snackbarService.open('Snackbar message', 'Action')
  }
}
