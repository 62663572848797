<ng-container *transloco="let t; read: 'reset'">
  <div class="bg-surface">
    <div class="container">
      <div class="flex h-screen flex-wrap items-center p-5">
        <div class="order-first mx-auto mb-3 mt-9 w-full px-5 md:order-last md:mb-0 md:w-1/2">
          <sb-logo class="object-contain" />
        </div>
        <div class="my-5 w-full md:order-first md:w-4/12">
          @if (!submitted) {
          <sb-text variant="title" align="center" class="mb-0">{{ t("title") }}</sb-text>
          <sb-text variant="subtitle" align="center" muted class="mb-5">{{ t("subtitle") }}</sb-text>
          <form (ngSubmit)="onResetPassword()" [formGroup]="resetPasswordForm" class="grid">
            <mat-form-field>
              <mat-label>{{ t("form.password") }}</mat-label>
              <!-- Input group -->
              <input
                matInput
                [placeholder]="t('form.passwordPlaceholder')"
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                id="password"
                name="password"
              />
              <sb-button
                variant="icon"
                matSuffix
                [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                (sbClick)="showPassword = !showPassword"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="!showPassword"
              />
              @if (!resetPasswordForm.get('password').valid && resetPasswordForm.get('password').touched) {
              <mat-error>
                @if (resetPasswordForm.get('password').errors['required']) { {{ "validation.required" | transloco: {
                attribute: "reset.form.password" | transloco } }} } @if (
                resetPasswordForm.get('password').errors['pattern'] ||
                resetPasswordForm.get('password').errors['minLength'] ) { {{ "validation.regex" | transloco: {
                attribute: "reset.form.password" | transloco } }} }
              </mat-error>
              }
            </mat-form-field>
            <sb-card class="mb-5" [title]="t('requirements.title')">
              <ng-template #content>
                <sb-text>{{ t("requirements.subtitle") }}:</sb-text>
                <ul class="mb-0 pl-4">
                  <li><sb-text noMargin>{{ t("requirements.length") }}</sb-text></li>
                  <li><sb-text noMargin>{{ t("requirements.uppercase") }}</sb-text></li>
                  <li><sb-text noMargin>{{ t("requirements.lowercase") }}</sb-text></li>
                </ul>
              </ng-template>
            </sb-card>
            <sb-button [disabled]="!resetPasswordForm.valid" fill type="submit"> {{ t("form.button") }} </sb-button>
          </form>
          } @else {
          <sb-text variant="title" class="mb-3">{{ t("afterReset.title") }}</sb-text>
          <sb-text>{{ t("afterReset.info") }}</sb-text>
          <sb-button fill routerLink="/login">{{ t("afterReset.signin") }}</sb-button>
          }
          <ng-template #info>
            <sb-text variant="title" class="mb-3">{{ t("afterReset.title") }}</sb-text>
            <sb-text>{{ t("afterReset.info") }}</sb-text>
            <sb-button fill routerLink="/login">{{ t("afterReset.signin") }}</sb-button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
