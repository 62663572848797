import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { getBrowserLang } from '@ngneat/transloco'
import { Observable } from 'rxjs'

import { environment } from '~env'

export const acceptLanguageInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<any>> => {
  // Set Accept-Language header only for guest routes
  const needsHeader = request.url.includes('auth') && !request.url.includes('logout') && !request.url.includes('me')
  if (request.url.includes(environment.apiEndpoint) && needsHeader) {
    request = request.clone({
      setHeaders: {
        'Accept-Language': getBrowserLang(),
      },
    })
  }

  return next(request)
}
