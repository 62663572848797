import { Component, OnInit } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'

import { LogoComponent } from '~common/components/logo/logo.component'

@Component({
  selector: 'sb-info-section-promo',
  templateUrl: './info-section-promo.component.html',
  styleUrls: ['./info-section.component.scss'],
  standalone: true,
  imports: [LogoComponent, TranslocoModule],
})
export class InfoSectionPromoComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
