<ng-container *transloco="let t; read: 'forgot'">
  <div class="bg-surface">
    <div class="container">
      <div class="flex h-screen flex-wrap items-center p-5">
        <div class="order-first mx-auto mb-3 mt-9 w-full px-5 md:order-last md:mb-0 md:w-1/2">
          <sb-logo class="object-contain" />
        </div>
        <div class="my-5 w-full md:order-first md:w-4/12">
          <sb-text variant="title" align="center" class="mb-0">{{ t("title") }}</sb-text>
          <sb-text variant="subtitle" align="center" muted class="mb-5">{{ t("subtitle") }}</sb-text>
          @if (!submitted) {
          <!-- Form -->
          <form (ngSubmit)="onForgotPassword()" [formGroup]="forgotPasswordForm" class="grid">
            <!-- Email address -->
            <mat-form-field>
              <mat-label>{{ t("form.email") }}</mat-label>
              <input
                matInput
                [placeholder]="t('form.emailPlaceholder')"
                formControlName="email"
                id="email"
                type="email"
              />
              @if (!forgotPasswordForm.get('email').valid && forgotPasswordForm.get('email').touched) {
              <mat-error>
                @if (forgotPasswordForm.get('email').errors['required']) { {{ "validation.required" | transloco: {
                attribute: "signin.form.email" | transloco } }} } @if (forgotPasswordForm.get('email').errors['email'])
                { {{ "validation.regex" | transloco: { attribute: "signin.form.email" | transloco } }} }
              </mat-error>
              }
            </mat-form-field>
            <sb-button fill [disabled]="!forgotPasswordForm.valid" type="submit" class="mt-3">
              {{ t("form.button") }}
            </sb-button>
            <sb-text variant="label" align="center" class="mt-3" muted>
              {{ t("rememberYourPassword") }} <a routerLink="/login">{{ t("signin") }}</a>.
            </sb-text>
          </form>
          } @else {
          <div class="flex flex-wrap">
            <sb-text align="center">
              <span [innerHTML]="t('resetInfos')"></span>
            </sb-text>
          </div>
          }
        </div>
      </div>
      <!-- / .container -->
    </div>
  </div>
</ng-container>
