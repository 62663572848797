import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { RouterLink } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { ApiService } from '~core/services'

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.view.html',
  standalone: true,
  imports: [
    TranslocoModule,
    LogoComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    RouterLink,
  ],
})
export class ForgotPasswordView implements OnInit {
  forgotPasswordForm: FormGroup
  submitted = false

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
    })
  }

  onForgotPassword() {
    this.apiService
      .post('/v1.0/auth/forgot', { email: this.forgotPasswordForm.value.email })
      .subscribe(() => (this.submitted = true))
  }
}
