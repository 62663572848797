import { ApplicationConfig } from '@angular/core'
import { provideTranslocoScope } from '@ngneat/transloco'

import { translocoScopeLoader } from '~core/utils'

export const integrationsConfig: ApplicationConfig['providers'] = [
  provideTranslocoScope({
    scope: 'integrations',
    loader: translocoScopeLoader((lang: string, root: string) => import(`./${root}/${lang}.json`)),
  }),
]
