import { ViewportScroller } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, RouterLink } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-ig-account-help',
  templateUrl: './ig-account-help.component.html',
  styleUrls: ['./ig-account-help.component.scss'],
  standalone: true,
  imports: [TranslocoModule, RouterLink],
})
export class IgAccountHelpComponent implements OnInit, OnDestroy {
  private subscription = Subscription.EMPTY

  constructor(
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.subscription = this.route.fragment.pipe(filter((fragment) => !!fragment)).subscribe((fragment) => {
      setTimeout(() => this.scroll(fragment), 250)
    })
  }

  scroll(anchor: string) {
    this.viewportScroller.scrollToAnchor(anchor)
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
