import { NgIf, AsyncPipe } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, ParamMap, Router } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { Observable, of, Subscription, throwError } from 'rxjs'
import { catchError, first, map } from 'rxjs/operators'
import { FacebookAccountListComponent } from 'src/app/common/components/facebook-account-list/facebook-account-list.component'
import { FacebookLoginDialogComponent } from 'src/app/common/components/facebook-login-dialog/facebook-login-dialog.component'
import { LinkedinAccountListComponent } from 'src/app/common/components/linkedin-account-list/linkedin-account-list.component'
import { SpinnerComponent } from 'src/app/common/components/spinner/spinner.component'
import { Icon } from 'src/app/common/icons'
import { AppLayoutComponent } from 'src/app/common/layout/app-layout/app-layout.component'
import { SidebarItem } from 'src/app/common/layout/sidebar/sidebar.component'
import { SidebarComponent } from 'src/app/common/layout/sidebar/sidebar.component'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'

import { FacebookApiService } from '~core/services/facebook-api.service'
import { LinkedinApiService } from '~core/services/linkedin-api.service'
import { DialogService } from '~core/services/ui/dialog.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { setBreadcrumbs } from '~core/store/ui/ui.actions'
import { selectIsMobileSidebar, selectSidebarExpanded } from '~core/store/ui/ui.selectors'
import logger from '~core/utils/logger'
import { truthy } from '~core/utils/operators'
import { ConfirmIntegrationDeleteComponent } from '~features/integrations/components/confirm-integration-delete/confirm-integration-delete.component'
import { LinkedinAccount, LinkedinAccountRequest } from '~features/integrations/models/linkedin-account.model'
import * as IntegrationActions from '~features/integrations/store/actions'

import { FacebookAccount, FacebookAccountRequest } from '../../models/facebook-account.model'
import * as fromIntegrations from '../../store/selectors'

@Component({
  selector: 'app-integrations',
  template: `
    <sb-app-layout *transloco="let t">
      <ng-template #sidenav>
        <sb-sidebar
          [items]="sidebarItems"
          [expanded]="expanded$ | async"
          [isMobileSidebar]="isMobileSidebar$ | async"
        />
      </ng-template>
      <sb-view>
        <ng-template #toolbar>
          <sb-view-header class="lg:container" [title]="t('integrations.ui.Integrations')" />
        </ng-template>
        <ng-template #content>
          <!-- Unauthorized Facebook Accounts Section -->
          <div class="grid gap-8 lg:container">
            <ng-container *ngIf="unauthFacebookAccounts$ | async as facebookAccounts">
              <sb-card *ngIf="facebookAccounts.length > 0" [title]="t('integrations.ui.UnauthorizedFacebookAccounts')">
                <ng-template #content>
                  <app-facebook-account-list
                    *ngIf="(isFacebookAccountsLoading$ | async) === false"
                    [facebookAccounts]="facebookAccounts"
                    (refreshFacebookAccount)="onRefreshFacebookAccount($event)"
                  />
                </ng-template>
              </sb-card>
            </ng-container>

            <!-- Unauthorized Linkedin Accounts Section -->
            <ng-container *ngIf="unauthLinkedinAccounts$ | async as linkedinAccounts">
              <sb-card *ngIf="linkedinAccounts.length > 0" [title]="t('integrations.ui.UnauthorizedLinkedinAccounts')">
                <ng-template #content>
                  <app-linkedin-account-list
                    *ngIf="(isLinkedinAccountsLoading$ | async) === false"
                    [linkedinAccounts]="linkedinAccounts"
                    (refreshLinkedinAccount)="onRefreshLinkedinAccount($event)"
                  />
                  <app-spinner *ngIf="(isLinkedinAccountsLoading$ | async) === true"></app-spinner>
                </ng-template>
              </sb-card>
            </ng-container>

            <!-- All Facebook Accounts Section -->
            <sb-card [title]="t('integrations.ui.AllFacebookAccounts')">
              <ng-template #content>
                <app-facebook-account-list
                  *ngIf="(isFacebookAccountsLoading$ | async) === false"
                  [facebookAccounts]="facebookAccounts$ | async"
                  [enableDelete]="true"
                  [enableMakePrimary]="true"
                  (makePrimaryFacebookAccount)="onMakePrimaryFacebookAccount($event)"
                  (refreshFacebookAccount)="onRefreshFacebookAccount($event)"
                  (deleteFacebookAccount)="onDeleteFacebookAccount($event)"
                />
                <app-spinner *ngIf="(isFacebookAccountsLoading$ | async) === true"></app-spinner>
              </ng-template>
            </sb-card>

            <!-- All Linkedin Accounts Section -->
            <ng-container *ngIf="linkedinAccounts$ | async as linkedinAccounts">
              <sb-card *ngIf="linkedinAccounts.length > 0" [title]="t('integrations.ui.AllLinkedinAccounts')">
                <ng-template #content>
                  <app-linkedin-account-list
                    *ngIf="(isLinkedinAccountsLoading$ | async) === false"
                    [linkedinAccounts]="linkedinAccounts"
                    [enableDelete]="true"
                    [enableMakePrimary]="true"
                    (makePrimaryLinkedinAccount)="onMakePrimaryLinkedinAccount($event)"
                    (refreshLinkedinAccount)="onRefreshLinkedinAccount($event)"
                    (deleteLinkedinAccount)="onDeleteLinkedinAccount($event)"
                  />
                </ng-template>
              </sb-card>
            </ng-container>
          </div>
        </ng-template>
      </sb-view>
    </sb-app-layout>
  `,
  standalone: true,
  imports: [
    TranslocoModule,
    AppLayoutComponent,
    SidebarComponent,
    ViewComponent,
    ViewHeaderComponent,
    NgIf,
    CardComponent,
    FacebookAccountListComponent,
    LinkedinAccountListComponent,
    SpinnerComponent,
    AsyncPipe,
  ],
})
export class IntegrationsView implements OnInit, OnDestroy {
  expanded$: Observable<boolean>
  facebookAccounts$: Observable<FacebookAccount[]>
  integrationErrors$: Observable<HttpErrorResponse>
  isFacebookAccountsLoading$: Observable<boolean>
  isLinkedinAccountsLoading$: Observable<boolean>
  isMobileSidebar$: Observable<boolean>
  linkedinAccountErrors$: Observable<HttpErrorResponse>
  linkedinAccounts$: Observable<LinkedinAccount[]>
  sidebarItems: SidebarItem[] = [
    {
      path: '/projects',
      labelPath: 'navigation.Home',
      icon: Icon.Home,
      spacer: true,
    },
    {
      path: '/user/account',
      labelPath: 'navigation.Account',
      icon: Icon.Person,
    },
    {
      path: '/integrations',
      labelPath: 'navigation.Integrations',
      icon: Icon.MergeType,
    },
    {
      path: '/user/subscription',
      labelPath: 'navigation.subscription.Label',
      icon: Icon.Autorenew,
    },
  ]
  unauthFacebookAccounts$: Observable<FacebookAccount[]>
  unauthLinkedinAccounts$: Observable<LinkedinAccount[]>
  private subscription = Subscription.EMPTY

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private snackbarService: SnackbarService,
    private translateService: TranslocoService,
    private facebookApiService: FacebookApiService,
    private linkedinApiService: LinkedinApiService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
    this.store.dispatch(IntegrationActions.clearIntegrationErrors())
  }

  ngOnInit(): void {
    this.expanded$ = this.store.select(selectSidebarExpanded)
    this.isMobileSidebar$ = this.store.select(selectIsMobileSidebar)
    // this.initBreadcrumbs()
    this.selectFacebookAccountState()
    this.selectLinkedinAccountState()
    this.subscribeToLinkedinChannelConnection()
    this.subscribeToFacebookLogin()
    this.subscribeToLinkedinLogin()
    this.subscribeToErrors()
  }

  onDeleteFacebookAccount(facebookAccount: FacebookAccount): void {
    const dialogRef = this.dialogService.open(ConfirmIntegrationDeleteComponent, {
      data: {
        type: 'facebook-account',
        integration: facebookAccount,
      },
    })
    const deleteSubscription = dialogRef.componentInstance.confirmDelete.subscribe(() => {
      this.store.dispatch(IntegrationActions.deleteFacebookAccount({ _id: facebookAccount._id }))
      dialogRef.close()
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => deleteSubscription.unsubscribe())
  }

  onDeleteLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    const dialogRef = this.dialogService.open(ConfirmIntegrationDeleteComponent, {
      data: {
        type: 'linkedin-account',
        integration: linkedinAccount,
      },
    })
    const deleteSubscription = dialogRef.componentInstance.confirmDelete.subscribe(() => {
      this.store.dispatch(IntegrationActions.deleteLinkedinAccount({ _id: linkedinAccount._id }))
      dialogRef.close()
    })
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => deleteSubscription.unsubscribe())
  }

  onMakePrimaryFacebookAccount({ _id }: FacebookAccount): void {
    this.store.dispatch(IntegrationActions.makePrimaryFacebookAccount({ _id }))
  }

  onMakePrimaryLinkedinAccount({ _id }: LinkedinAccount): void {
    this.store.dispatch(IntegrationActions.makePrimaryLinkedinAccount({ _id }))
  }

  onRefreshFacebookAccount(facebookAccount: FacebookAccount): void {
    this.startFacebookLogin(facebookAccount._id)
  }

  onRefreshLinkedinAccount(linkedinAccount: LinkedinAccount): void {
    this.startLinkedinLogin(linkedinAccount._id)
  }

  private handleLoginError = (err) => {
    this.removeQueryParams()
    if (err instanceof Error) {
      this.snackbarService.error(err.message)
      return of(false)
    }
    return throwError(err)
  }

  private initBreadcrumbs(): void {
    this.translateService
      .selectTranslateObject('navigation')
      .pipe(first())
      .subscribe((navigation) => {
        this.store.dispatch(
          setBreadcrumbs({
            payload: [
              { icon: 'home', url: ['/projects'] },
              { label: navigation.Integrations, url: ['/integrations'] },
            ],
          }),
        )
      })
  }

  private removeQueryParams(): void {
    this.router.navigate([this.router.url.split('?')[0]])
  }

  private selectFacebookAccountState(): void {
    this.isFacebookAccountsLoading$ = this.store.select(fromIntegrations.selectIsLoadingFacebookAccounts)
    this.unauthFacebookAccounts$ = this.store.select(fromIntegrations.selectFullyUnauthorizedFacebookAccounts)
    this.facebookAccounts$ = this.store.select(fromIntegrations.selectFacebookAccounts)
  }

  private selectLinkedinAccountState(): void {
    this.isLinkedinAccountsLoading$ = this.store.select(fromIntegrations.selectIsLoadingLinkedinAccounts)
    this.unauthLinkedinAccounts$ = this.store.select(fromIntegrations.selectFullyUnauthorizedLinkedinAccounts)
    this.linkedinAccounts$ = this.store.select(fromIntegrations.selectLinkedinAccounts)
  }

  private startFacebookLogin(facebookAccountId: string): void {
    const dialogRef = this.dialogService.open(FacebookLoginDialogComponent)
    const subscription = dialogRef.componentInstance.confirm.subscribe((confirm: boolean) => {
      if (confirm) {
        const url = `${window.location.origin}/integrations?id=${facebookAccountId}&type=facebook-account`
        this.facebookApiService.login(url)
      } else {
        dialogRef.close()
        subscription.unsubscribe()
      }
    })
  }

  private startLinkedinLogin(linkedinAccountId: string): void {
    const url = `${window.location.origin}/integrations?id=${linkedinAccountId}&type=linkedin-account`
    this.linkedinApiService.login(url)
  }

  private subscribeToErrors(): void {
    this.integrationErrors$ = this.store.select(fromIntegrations.selectIntegrationErrors).pipe(truthy())
    this.integrationErrors$.subscribe((response) => {
      this.snackbarService.warning(response.error.message)
    })
  }

  private subscribeToFacebookLogin(): void {
    this.route.queryParamMap
      .pipe(first(), map(this.validateFacebookLogin), catchError(this.handleLoginError), truthy())
      .subscribe((queryParams: ParamMap) => {
        logger.debug(queryParams)
        const id = queryParams.get('id')
        const payload: FacebookAccountRequest = {
          id,
          code: queryParams.get('code'),
          facebook_redirect_uri: `${window.location.origin}/integrations?id=${id}&type=facebook-account`,
        }
        this.removeQueryParams()
        this.store.dispatch(IntegrationActions.refreshFacebookAccount(payload))
      })
  }

  private subscribeToLinkedinChannelConnection(): void {
    this.route.queryParamMap
      .pipe(first(), map(this.validateLinkedinChannelLogin), catchError(this.handleLoginError), truthy())
      .subscribe((queryParams: ParamMap) => {
        const params = {
          code: queryParams.get('code'),
          project: queryParams.get('project'),
          from: queryParams.get('from'),
          type: queryParams.get('type'),
        }
        logger.debug(queryParams)
        this.router.navigate(['/projects', queryParams.get('project'), 'channels'], { queryParams: params })
      })
  }

  private subscribeToLinkedinLogin(): void {
    this.route.queryParamMap
      .pipe(first(), map(this.validateLinkedinLogin), catchError(this.handleLoginError), truthy())
      .subscribe((queryParams: ParamMap) => {
        logger.debug(queryParams)
        const id = queryParams.get('id')
        const payload: LinkedinAccountRequest = {
          id,
          code: queryParams.get('code'),
          redirect_uri: `${window.location.origin}/integrations?id=${id}&type=linkedin-account`,
        }
        this.removeQueryParams()
        this.store.dispatch(IntegrationActions.refreshLinkedinAccount(payload))
      })
  }

  private validateFacebookLogin = (queryParams: ParamMap) => {
    if (!this.validateFacebookParams(queryParams)) {
      return false
    }
    const grantedScopes = queryParams.get('granted_scopes').split(',')
    this.facebookApiService.checkScopes(this.facebookApiService.defaultScopes, grantedScopes)
    return queryParams
  }

  private validateFacebookParams(params: ParamMap): boolean {
    return (
      params.has('granted_scopes') &&
      params.has('code') &&
      params.has('id') &&
      params.get('type') === 'facebook-account'
    )
  }

  private validateLinkedinChannelLogin = (queryParams: ParamMap) => {
    if (!this.validateLinkedinChannelParams(queryParams)) {
      return false
    }
    return queryParams
  }

  private validateLinkedinChannelParams(params: ParamMap): boolean {
    return (
      params.has('code') && params.has('project') && params.get('from') === 'project-channels' && params.has('type')
    )
  }

  private validateLinkedinLogin = (queryParams: ParamMap) => {
    if (!this.validateLinkedinParams(queryParams)) {
      return false
    }
    return queryParams
  }

  private validateLinkedinParams(params: ParamMap): boolean {
    return params.has('code') && params.has('id') && params.get('type') === 'linkedin-account'
  }
}
