<ng-container *transloco="let t; read: 'signin'">
  <!--  <div class="bg-surface container">-->
  <div class="bg-surface">
    <div class="container">
      <div class="flex h-screen flex-wrap items-center p-5">
        <div class="order-first mx-auto mb-3 mt-9 w-full px-5 md:order-last md:mb-0 md:w-1/2">
          <sb-logo class="object-contain" />
        </div>
        <div class="my-5 w-full md:order-first md:w-4/12">
          <sb-text variant="title" align="center" class="mb-9">{{ t("title") }}</sb-text>
          <form (ngSubmit)="onSignIn(dialogFb)" [formGroup]="signInForm" class="grid">
            <sb-button variant="outlined" [icon]="Icons.Facebook" (sbClick)="onClickFbLogin()" fill class="mb-5">
              {{ t("facebookLogin") }}
            </sb-button>
            <sb-text variant="body" [error]="invalidCredentials">
              <span [innerHTML]="statusMessage"></span>
            </sb-text>
            <!-- Email address -->
            <mat-form-field>
              <mat-label>{{ t("form.email") }}</mat-label>
              <input
                matInput
                formControlName="email"
                id="email"
                required
                [placeholder]="t('form.emailPlaceholder')"
                type="email"
              />
              <mat-error *ngIf="!signInForm.get('email').valid && signInForm.get('email').touched">
                <ng-container *ngIf="signInForm.get('email').errors['required']">
                  {{ "validation.required" | transloco: { attribute: "signin.form.email" | transloco } }}
                </ng-container>
                <ng-container *ngIf="signInForm.get('email').errors['email']">
                  {{ "validation.regex" | transloco: { attribute: "signin.form.email" | transloco } }}
                </ng-container>
              </mat-error>
            </mat-form-field>
            <!-- Password -->
            <sb-text variant="label" align="end">
              <a class="form-text small text-muted" routerLink="/forgot-password" tabindex="-1">
                {{ t("forgotPassword") }}
              </a>
            </sb-text>
            <mat-form-field>
              <mat-label>{{ t("form.password") }}</mat-label>
              <!-- Input group -->
              <input
                matInput
                [placeholder]="t('form.passwordPlaceholder')"
                [type]="showPassword ? 'text' : 'password'"
                formControlName="password"
                id="password"
                name="password"
              />
              <sb-button
                variant="icon"
                matSuffix
                [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                (click)="onShowPassword()"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="!showPassword"
              />
              <mat-error *ngIf="!signInForm.get('password').valid && signInForm.get('password').touched">
                {{ "validation.required" | transloco: { attribute: "signin.form.password" | transloco } }}
              </mat-error>
            </mat-form-field>
            <sb-button fill type="submit" class="mt-3"> {{ t("form.button") }} </sb-button>
            <sb-text variant="label" align="center" class="mt-3" muted>
              {{ t("doNotHaveAnAccount") }} <a routerLink="/register">{{ t("signup") }}</a>.
            </sb-text>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- / .container -->
</ng-container>

<ng-template #dialogFb>
  <sb-dialog title="Hi Facebook Tester!">
    <ng-template #content>
      <sb-text>
        Since this is a published app we cannot test new features on the production environment. Please access the
        staging environment, as we indicated in the app review request details. This is the url:
        <a href="https://staging.app.sbam.io">https://staging.app.sbam.io</a>
      </sb-text>
    </ng-template>
  </sb-dialog>
</ng-template>

<ng-template #invalidFacebookLogin>
  <sb-dialog title="Ops! Qualcosa non va...">
    <ng-template #content>
      <sb-text>
        Sembra che il tuo account Facebook non sia associato al nessun account su Sbam oppure non è abilitato per il
        login!
      </sb-text>
      <sb-text>
        Se ti sei già registrato fai il login con email e password per completare la procedura di associazione.
      </sb-text>
    </ng-template>
    <ng-template #actions>
      <sb-button variant="outlined" (click)="invalidFBLoginDialogRef.close()">Login</sb-button>
      <sb-button (click)="onClickGoToRegister()">Registrati</sb-button>
    </ng-template>
  </sb-dialog>
</ng-template>
