import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { ActivatedRoute, ParamMap, RouterLink } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { Subscription } from 'rxjs'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'
import { Icon } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { ApiService } from '~core/services'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.view.html',
  standalone: true,
  imports: [
    TranslocoModule,
    LogoComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ButtonComponent,
    CardComponent,
    RouterLink,
  ],
})
export class ResetPasswordView implements OnInit, OnDestroy {
  resetPasswordForm: FormGroup
  showPassword: boolean
  submitted = false
  protected Icons = Icon
  private paramSubscription = Subscription.EMPTY
  private querySubscription = Subscription.EMPTY

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
  ) {}

  ngOnDestroy(): void {
    this.paramSubscription.unsubscribe()
    this.querySubscription.unsubscribe()
  }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8), // must be at least 8 characters
        Validators.pattern(/[A-Z]/), // must contain at least one lowercase
        Validators.pattern(/[a-z]/), // must contain at least one uppercase
      ]),
      token: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
    })

    this.paramSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      this.resetPasswordForm.controls['token'].setValue(params.get('token'))
    })

    this.querySubscription = this.route.queryParamMap.subscribe((params) => {
      this.resetPasswordForm.controls['email'].setValue(params.get('email'))
    })
  }

  onResetPassword() {
    const body = {
      email: this.resetPasswordForm.value.email,
      password: this.resetPasswordForm.value.password,
      token: this.resetPasswordForm.value.token,
    }
    this.apiService.post('/v1.0/auth/reset', body).subscribe(() => (this.submitted = true))
  }
}
