import { NgClass } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TranslocoModule } from '@ngneat/transloco'
import { ViewHeaderComponent } from 'src/app/common/layout/view-header/view-header.component'
import { ViewComponent } from 'src/app/common/layout/view/view.component'

import { environment } from '~env'
import { User } from '~features/user/models/user.model'

import { AdminService } from '../../admin.service'

@Component({
  selector: 'app-support',
  standalone: true,
  imports: [ViewComponent, ViewHeaderComponent, NgClass, FormsModule, ReactiveFormsModule, TranslocoModule],
  template: `
    <sb-view>
      <ng-template #toolbar>
        <sb-view-header [title]="'Users'" />
      </ng-template>
      <ng-template #content>
        <div class="old">
          <div class="col-12">
            @if (firstPageUrl !== lastPageUrl) {
              <nav aria-label="Report list page navigation" class="mt-3">
                <ul class="pagination pagination-sm justify-content-end">
                  @for (link of links; track link; let isFirst = $first; let isLast = $last) {
                    <li [ngClass]="{ active: link.active, disabled: !link.url }" class="page-item">
                      <a (click)="onChangePage(link.url)" class="page-link">
                        @if (isFirst) {
                          <
                        }
                        @if (!isFirst && !isLast) {
                          {{ link.label }}
                        }
                        @if (isLast) {
                          >
                        }
                      </a>
                    </li>
                  }
                </ul>
              </nav>
            }

            @if (total > 0) {
              <div class="d-flex justify-content-end">
                <small>{{ from }} - {{ to }} {{ 'words.of' | transloco }} {{ total }}</small>
              </div>
            }

            <form (ngSubmit)="onSearch()" [formGroup]="searchForm">
              <div class="input-group input-group-flush input-group-merge">
                <input
                  class="form-control form-control-prepended search"
                  formControlName="search"
                  placeholder="Search user email"
                  type="search"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <!--<lucide-icon name="search"></lucide-icon>-->
                  </div>
                </div>
              </div>
            </form>

            <hr />

            <table class="table-striped table-responsive table">
              <thead class="thead-dark">
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Plan</th>
                  <th class="text-center" scope="col">Credit card</th>
                  <th scope="col">Projects</th>
                  <th scope="col">IG Account</th>
                  <th scope="col">FB Pages</th>
                  <th scope="col">FB Ad Accounts</th>
                  <th class="text-center" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody>
                @for (user of users; track user) {
                  <tr>
                    <td>{{ user.email }}</td>
                    <td>{{ user.firstName }}</td>
                    <td>{{ user.lastName }}</td>
                    <td>{{ user.plan?.label }}</td>
                    <td class="text-center">
                      <!--<lucide-icon *ngIf="user.hasPaymentMethod" class="text-success" name="check-circle"></lucide-icon>-->
                      <!--<lucide-icon *ngIf="!user.hasPaymentMethod" class="text-danger" name="x-circle"></lucide-icon>-->
                    </td>
                    <td class="text-right">{{ user.projects?.length }}</td>
                    <td class="text-right">{{ user.getChannelsCountByType('instagram-account') }}</td>
                    <td class="text-right">{{ user.getChannelsCountByType('facebook-page') }}</td>
                    <td class="text-right">{{ user.getChannelsCountByType('facebook-ad-account') }}</td>
                    <td>
                      <button
                        (click)="onLoginAsUser(user._id)"
                        [disabled]="disabled"
                        class="btn btn-sm btn-link font-weight-bold"
                      >
                        Login as user
                      </button>
                    </td>
                  </tr>
                }
              </tbody>
            </table>

            @if (firstPageUrl !== lastPageUrl) {
              <nav aria-label="Report list page navigation" class="mt-3">
                <ul class="pagination pagination-sm justify-content-end">
                  @for (link of links; track link; let isFirst = $first; let isLast = $last) {
                    <li [ngClass]="{ active: link.active, disabled: !link.url }" class="page-item">
                      <a (click)="onChangePage(link.url)" class="page-link">
                        @if (isFirst) {
                          <
                        }
                        @if (!isFirst && !isLast) {
                          {{ link.label }}
                        }
                        @if (isLast) {
                          >
                        }
                      </a>
                    </li>
                  }
                </ul>
              </nav>
            }
          </div>
        </div>
      </ng-template>
    </sb-view>
  `,
  styles: [
    `
      .page-item {
        box-shadow: 0rem 0.75rem 1.5rem rgba(18, 38, 63, 0.1);

        &:last-child {
          border-top-right-radius: 0.6rem;
          border-bottom-right-radius: 0.6rem;
        }
      }

      .pagination {
        overflow-x: auto;
      }

      .pagination li {
        display: inline-block;
      }
    `,
  ],
})
export class SupportComponent implements OnInit {
  disabled = false
  firstPageUrl: string
  from: number
  lastPageUrl: string
  links: {
    url: string
    label: string
    active: boolean
  }[]
  pages: number[]
  path: string
  searchForm: UntypedFormGroup
  to: number
  total: number
  users: User[]

  constructor(
    private adminService: AdminService,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.adminService.users().subscribe((response) => this.updateData(response))
    this.searchForm = new UntypedFormGroup({
      search: new UntypedFormControl(null, Validators.required),
    })
  }

  onChangePage(endpoint: string) {
    const search = this.searchForm.value.search
    if (search) {
      endpoint = endpoint + '&search=' + search
    }
    this.httpClient.get<{ data: any }>(endpoint).subscribe((response) => this.updateData(response))
  }

  onLoginAsUser(_id: string) {
    this.disabled = true
    this.adminService.loginAsUser(_id).subscribe(() => location.replace(environment.appUrl))
  }

  onSearch() {
    const search = this.searchForm.value.search
    if (search) {
      this.onChangePage(this.path + '?search=' + search)
    } else {
      this.onChangePage(this.path)
    }
  }

  private updateData(response: any) {
    this.users = response.data.map((user) => new User(user))
    this.pages = Array(Math.min(Math.ceil(response.meta.total / response.meta.per_page), 5))
      .fill(1)
      .map((x, i) => i + 1)
    this.pages = Array(Math.min(Math.ceil(response.meta.total / response.meta.per_page), 5))
      .fill(1)
      .map((x, i) => i + 1)
    this.from = response.meta.from
    this.to = response.meta.to
    this.links = response.meta.links
    this.total = response.meta.total
    this.path = response.meta.path.replace('http://localhost', 'http://localhost:8000')
    this.firstPageUrl = response.links.first
    this.lastPageUrl = response.links.last
  }
}
