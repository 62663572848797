import { AsyncPipe } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { MatDialogRef } from '@angular/material/dialog'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable } from 'rxjs'

import { UserService } from '~core/services'
import { User } from '~features/user/models/user.model'

import { ButtonComponent } from '../../ui/button/button.component'
import { DialogComponent } from '../../ui/dialog/dialog.component'
import { TextComponent } from '../../ui/text/text.component'

@Component({
  selector: 'sb-update-dialog',
  template: `
    <sb-dialog *transloco="let t; read: 'modals.updateAvailable'" [title]="t('title')">
      <ng-template #content>
        <div class="d-flex flex-column justify-center">
          <img class="mx-auto my-12 w-1/2" src="/assets/svg-icons/rocket.svg" style="max-height: 170px" />
          <sb-text
            align="center"
            [innerHTML]="t('text', { name: (user | async) ? ' ' + (user | async).firstName : '' })"
          />
        </div>
      </ng-template>
      <ng-template #actions>
        <sb-button variant="text" (click)="dialogRef.close()">{{ t('updateLater') }}</sb-button>
        <sb-button (click)="onUpdate()">{{ t('updateNow') }}</sb-button>
      </ng-template>
    </sb-dialog>
  `,
  standalone: true,
  imports: [TranslocoModule, DialogComponent, TextComponent, ButtonComponent, AsyncPipe],
})
export class UpdateDialogComponent implements OnInit {
  user: Observable<User>

  constructor(
    public dialogRef: MatDialogRef<UpdateDialogComponent>,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.user = this.userService.user
  }

  onUpdate() {
    window.location.reload()
  }
}
