<div class="main-content">
  @if (notFound === true) {
    <app-not-found [showButton]="false"></app-not-found>
  } @else {
    <div class="fb-deletion container">
      <div class="card mt-5">
        <div class="card-body">
          <ul class="list-group list-group-flush list my-n3">
            <li class="list-group-item px-0">
              <div class="row align-items-center justify-content-between mb-n3">
                <div class="col-auto">
                  <h3>Facebook Data Deletion Status</h3>
                </div>
                <div class="col-auto">
                  <h3>
                    @if (deleted) {
                      <span class="badge badge-soft-success">Deleted</span>
                    } @else {
                      <h3>
                        <span class="badge badge-lg badge-soft-info">In progress</span>
                      </h3>
                    }
                  </h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  }
</div>
