<div class="mb-9 flex flex-wrap justify-center md:justify-start">
  <div class="lg:1/3 w-1/2">
    <sb-logo class="object-contain" />
  </div>
</div>
<div *transloco="let t" class="info-container">
  <div class="info-title">
    {{ t("auth.ui.infoSection.WhatToExpect") }}
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img src="/assets/img/stats.png" />
    </div>
    <div [innerHTML]="t('auth.ui.infoSection.Reports')" class="info-label"></div>
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img src="/assets/img/analytics.png" />
    </div>
    <div [innerHTML]="t('auth.ui.infoSection.Analytics')" class="info-label"></div>
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img src="/assets/img/piggy-bank.png" />
    </div>
    <div [innerHTML]="t('auth.ui.infoSection.Savings')" class="info-label"></div>
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img src="/assets/img/customerly.png" />
    </div>
    <div [innerHTML]="t('auth.ui.infoSection.Support')" class="info-label"></div>
  </div>
</div>
<div class="api-partners">
  <img alt="facebook partner" class="mr-4" src="/assets/img/facebook-partner.png" />
  <img alt="linkedin partner" class="" src="/assets/img/linkedin-partner.png" />
</div>
