import { HttpClient } from '@angular/common/http'
import { inject } from '@angular/core'
import {
  TRANSLOCO_MISSING_HANDLER,
  Translation,
  TranslocoLoader,
  TranslocoMissingHandler,
  provideTransloco,
} from '@ngneat/transloco'
import { provideTranslocoLocale } from '@ngneat/transloco-locale'
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat'

import { AppConfig } from '~core/config/config'

export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient)

  getTranslation(lang: string) {
    // TODO: ensure that lang is loaded before load components (also for scoped translations)
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`)
  }
}

export class MissingHandler implements TranslocoMissingHandler {
  handle() {
    return ''
  }
}

export const translocoProviders = [
  provideTransloco({
    config: AppConfig.TRANSLOCO,
    loader: TranslocoHttpLoader,
  }),
  provideTranslocoLocale(AppConfig.TRANSLOCO_LOCALE),
  provideTranslocoMessageformat(AppConfig.TRANSLOCO_MESSAGE_FORMAT),
  [{ provide: TRANSLOCO_MISSING_HANDLER, useClass: MissingHandler }],
]
