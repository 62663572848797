import { Component, Input, OnInit } from '@angular/core'
import { RouterLink } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  standalone: true,
  imports: [TranslocoModule, RouterLink],
})
export class NotFoundComponent {
  @Input() showButton = true
}
