<div class="mb-9 flex flex-wrap items-center justify-center">
  <div class="w-1/2 px-3">
    <sb-logo class="object-contain" />
  </div>
  <div class="separator w-1/12">X</div>
  <div class="w-5/12">
    <img alt="Marketing espresso" src="/assets/img/marketing-espresso.png" />
  </div>
</div>
<div *transloco="let t" class="info-container promo">
  <div class="info-item">
    <div class="info-icon">
      <img class="img-fluid" src="/assets/img/speedometer.png" />
    </div>
    <div [innerHTML]="t('auth.ui.marketingEspressoInfoSection.WhatIsSbam')" class="info-label"></div>
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img class="img-fluid" src="/assets/img/gift.png" />
    </div>
    <div [innerHTML]="t('auth.ui.marketingEspressoInfoSection.Discount')" class="info-label"></div>
  </div>
  <div class="info-item">
    <div class="info-icon">
      <img class="img-fluid" src="/assets/img/customerly.png" />
    </div>
    <div [innerHTML]="t('auth.ui.marketingEspressoInfoSection.Demo')" class="info-label"></div>
  </div>
</div>
<div class="api-partners">
  <img alt="facebook partner" class="img-fluid mr-4" src="/assets/img/facebook-partner.png" />
  <img alt="linkedin partner" class="img-fluid" src="/assets/img/linkedin-partner.png" />
</div>
