import { UpperCasePipe } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute, ParamMap, Router, RouterLink } from '@angular/router'
import { TranslocoService, TranslocoModule } from '@ngneat/transloco'
import { of, Subscription, throwError } from 'rxjs'
import { catchError, switchMap } from 'rxjs/operators'
import { Icon } from 'src/app/common/icons'
import { ButtonComponent } from 'src/app/common/ui/button/button.component'
import { CardComponent } from 'src/app/common/ui/card/card.component'
import { TextComponent } from 'src/app/common/ui/text/text.component'

import { UserService } from '~core/services'
import { FacebookApiService } from '~core/services/facebook-api.service'
import { Helper } from '~core/services/helper.service'
import { SnackbarService } from '~core/services/ui/snackbar.service'
import { truthy } from '~core/utils/operators'
import { User } from '~features/user/models/user.model'

import { InfoSectionPromoComponent } from '../../components/info-section/info-section-promo.component'
import { InfoSectionComponent } from '../../components/info-section/info-section.component'

@Component({
  selector: 'app-signup',
  standalone: true,
  imports: [
    TranslocoModule,
    InfoSectionComponent,
    InfoSectionPromoComponent,
    CardComponent,
    TextComponent,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    RouterLink,
    UpperCasePipe,
  ],
  template: `
    <ng-container *transloco="let t">
      <div class="bg-surface">
        <div class="container">
          <div class="flex h-screen flex-wrap items-center p-5">
            <div class="mx-auto mb-5 mt-9 w-full md:mb-0 md:mt-0 md:w-1/2 md:pr-9">
              @if (!isMarketingEspressoPromo) {
                <sb-info-section></sb-info-section>
              }
              @if (isMarketingEspressoPromo) {
                <sb-info-section-promo></sb-info-section-promo>
              }
            </div>
            <div class="w-full md:w-1/2 lg:pl-5">
              <sb-card>
                <ng-template #content>
                  <sb-text variant="title" align="center" class="mb-4 mt-2">{{ t('signup.title') }}</sb-text>
                  <form (ngSubmit)="onSignUp()" [formGroup]="form" class="grid grid-cols-2 gap-4">
                    <sb-button
                      variant="outlined"
                      [icon]="Icons.Facebook"
                      (sbClick)="onSignupViaFacebook()"
                      [disabled]="isLoading"
                      fill
                      type="button"
                      id="fb_register"
                      class="register col-span-2 my-7 lg:my-9"
                    >
                      {{ t('signup.facebookLogin') }}
                    </sb-button>
                    <mat-form-field class="col-span-2 md:col-span-1">
                      <mat-label>{{ t('signup.form.firstName') }}</mat-label>
                      <input
                        matInput
                        [placeholder]="t('signup.form.firstName')"
                        formControlName="firstName"
                        type="text"
                      />
                      @if (!form.get('firstName').valid && form.get('firstName').touched) {
                        <mat-error>
                          {{ 'validation.required' | transloco: { attribute: 'signup.form.firstName' | transloco } }}
                        </mat-error>
                      }
                    </mat-form-field>
                    <mat-form-field class="col-span-2 md:col-span-1">
                      <mat-label>{{ t('signup.form.lastName') }}</mat-label>
                      <input
                        matInput
                        [placeholder]="t('signup.form.lastName')"
                        formControlName="lastName"
                        type="text"
                      />
                      @if (!form.get('lastName').valid && form.get('lastName').touched) {
                        <mat-error>
                          {{ 'validation.required' | transloco: { attribute: 'signup.form.lastName' | transloco } }}
                        </mat-error>
                      }
                    </mat-form-field>
                    <mat-form-field class="col-span-2">
                      <mat-label>{{ t('signup.form.email') }}</mat-label>
                      <input matInput [placeholder]="t('signup.form.email')" formControlName="email" type="email" />
                      @if (!form.get('email').valid && form.get('email').touched) {
                        <mat-error>
                          @if (form.get('email').errors['required']) {
                            {{ 'validation.required' | transloco: { attribute: 'signup.form.email' | transloco } }}
                          }
                          @if (form.get('email').errors['email']) {
                            {{ 'validation.regex' | transloco: { attribute: 'signup.form.email' | transloco } }}
                          }
                        </mat-error>
                      }
                    </mat-form-field>
                    <mat-form-field class="col-span-2">
                      <mat-label>{{ t('signup.form.password') }}</mat-label>
                      <!-- Input group -->
                      <input
                        matInput
                        [placeholder]="t('form.passwordPlaceholder')"
                        [type]="showPassword ? 'text' : 'password'"
                        formControlName="password"
                      />
                      <sb-button
                        variant="icon"
                        matSuffix
                        [icon]="showPassword ? Icons.VisibilityOff : Icons.Visibility"
                        (sbClick)="showPassword = !showPassword"
                        [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="!showPassword"
                      />
                      @if (!form.get('password').valid && form.get('password').touched) {
                        <mat-error>
                          @if (form.get('password').errors['required']) {
                            {{ 'validation.required' | transloco: { attribute: 'reset.form.password' | transloco } }}
                          }
                          @if (form.get('password').errors['pattern'] || form.get('password').errors['minLength']) {
                            {{ 'validation.regex' | transloco: { attribute: 'reset.form.password' | transloco } }}
                          }
                        </mat-error>
                      }
                      <mat-hint>{{ t('auth.info.PasswordRequirements') }}</mat-hint>
                    </mat-form-field>
                    <div class="col-span-2 mt-6 grid">
                      <sb-text align="center" variant="label" class="mb-1">
                        {{ t('signup.policy.pre') }}
                        <a href="//sbam.io/terms" target="_blank">{{ t('signup.policy.terms') }}</a>
                        {{ t('signup.policy.and') }}
                        <a href="//sbam.io/privacy" target="_blank">{{ t('signup.policy.privacy') }}</a>
                      </sb-text>
                      <sb-button fill [disabled]="isLoading" id="mail_register" class="register" type="submit">
                        {{ t('signup.form.button') }}
                      </sb-button>
                      <sb-text variant="label" align="center" class="mt-1">{{
                        t('signup.subtitle') | uppercase
                      }}</sb-text>
                      <sb-text variant="label" align="center" class="mt-5" muted>
                        {{ t('signup.alreadyHaveAnAccount') }}
                        <a routerLink="/login">{{ t('signup.signin') }}</a>
                      </sb-text>
                    </div>
                  </form>
                </ng-template>
              </sb-card>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  `,
})
export class SignupView implements OnInit, OnDestroy {
  form: FormGroup
  isLoading = false
  isMarketingEspressoPromo = false
  isPasswordFocused = false
  showPassword: boolean

  private coupon: string
  private facebookAuthCode: string
  private facebookLoginSubscription = Subscription.EMPTY
  private facebookRedirectUri: string
  private routeSubscription = Subscription.EMPTY
  protected Icons = Icon

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private facebookService: FacebookApiService,
    private translateService: TranslocoService,
    private helper: Helper,
    private sanitizer: DomSanitizer,
    private snackbarService: SnackbarService,
  ) {}

  initForm(): void {
    this.form = new FormGroup({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [
        Validators.required,
        Validators.minLength(8), // must be at least 8 characters
        Validators.pattern(/[A-Z]/), // must contain at least one lowercase
        Validators.pattern(/[a-z]/), // must contain at least one uppercase
      ]),
    })
  }

  initRouteSubscription(): void {
    this.routeSubscription = this.route.paramMap.subscribe((params: ParamMap) => {
      // If the url has coupon param, then the user is registering with one time offer coupon
      if (params.has('coupon') && params.get('coupon') === 'marketing-espresso-15') {
        this.coupon = params.get('coupon')
        this.isMarketingEspressoPromo = true
      }
    })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe()
  }

  ngOnInit(): void {
    this.facebookRedirectUri = window.location.origin + window.location.pathname
    this.initRouteSubscription()
    this.subscribeToFacebookLogin()
    this.initForm()
  }

  onSignUp(): void {
    if (!this.form.valid) {
      this.helper.touchAllFormFields(this.form)
      return
    }

    this.isLoading = true

    const user = new User()
    const formValues = this.form.value
    user.firstName = formValues.firstName
    user.lastName = formValues.lastName
    user.email = formValues.email
    user.password = formValues.password

    this.userService
      .create(user)
      .pipe(
        switchMap(this.handleCoupon),
        catchError((err) => {
          this.isLoading = false
          return throwError(err)
        }),
      )
      .subscribe(() => this.router.navigateByUrl('/'))
  }

  onSignupViaFacebook(): void {
    this.isLoading = true
    this.facebookService.login(this.facebookRedirectUri)
  }

  subscribeToFacebookLogin(): void {
    this.facebookLoginSubscription = this.route.queryParamMap
      .pipe(
        switchMap((queryParams: ParamMap) => {
          if (!queryParams.has('granted_scopes') || !queryParams.has('code')) {
            this.removeQueryParams()
            return of(false)
          }
          this.isLoading = true
          const grantedScopes = queryParams.get('granted_scopes').split(',')
          this.facebookService.checkScopes(['email'], grantedScopes)
          this.facebookAuthCode = queryParams.get('code')
          return this.userService.facebookRegister(this.facebookAuthCode, this.facebookRedirectUri)
        }),
        truthy(),
        switchMap(this.handleCoupon),
        catchError((error) => {
          if (error instanceof Error) {
            this.snackbarService.error(error.message)
          }
          this.isLoading = false
          this.removeQueryParams()
          return throwError(error)
        }),
      )
      .subscribe(() => {
        this.removeQueryParams()
        this.redirectAfterSignup()
      })
  }

  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword
  }

  private handleCoupon = (user: User) => {
    if (this.coupon && this.isMarketingEspressoPromo) {
      return this.userService.redeemCoupon(this.coupon)
    }
    return of(user)
  }

  private redirectAfterSignup() {
    this.router.navigate(['/projects/new'])
  }

  private removeQueryParams(): void {
    this.router.navigate([this.router.url.split('?')[0]])
  }
}
