import { Routes } from '@angular/router'

import { authenticationGuard } from '~core/guards/authentication.guard'
import { authorizationGuard } from '~core/guards/authorization.guard'
import { AdminRouter } from '~features/admin/routers/admin.router'
import { DesignLibraryComponent } from '~features/admin/views/design-library/design-library.component'
import { MauticComponent } from '~features/admin/views/mautic/mautic.component'
import { SupportComponent } from '~features/admin/views/support/support.component'

import { SentimentReviewView } from './views/sentiment-review/sentiment-review.view'

export const ADMIN_ROUTES: Routes = [
  {
    path: 'admin',
    component: AdminRouter,
    canActivate: [authenticationGuard, authorizationGuard],
    canActivateChild: [authenticationGuard, authorizationGuard],
    children: [
      { path: '', redirectTo: '/admin/support', pathMatch: 'full' },
      { path: 'support', component: SupportComponent },
      { path: 'sentiment-review', component: SentimentReviewView },
      { path: 'mautic', component: MauticComponent },
      { path: 'design-library', component: DesignLibraryComponent },
    ],
  },
]
