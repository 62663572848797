import { HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { finalize } from 'rxjs/operators'

import { setIsLoading } from '~core/store/core.actions'
import logger from '~core/utils/logger'

@Injectable({
  providedIn: 'root',
})
export class LoadingInterceptor {
  activeRequests = 0
  countdown = 400
  private timeout // TODO: FIX TYPE: NodeJS.Timeout

  constructor(private store: Store) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!this.shouldSetIsLoading(request)) {
      logger.debug('Skipping loading indicator', request.url)
      return next.handle(request)
    }

    if (this.activeRequests === 0) {
      // Start the timeout to handle minimum loading duration
      this.timeout = setTimeout(() => {
        if (this.activeRequests === 0) {
          // Loading ends at timeout only if there are no active requests
          this.store.dispatch(setIsLoading({ payload: false }))
        }
        // Clear timeout after minimum loading duration
        this.timeout = null
      }, this.countdown)
      this.store.dispatch(setIsLoading({ payload: true }))
    }

    this.activeRequests++

    return next.handle(request).pipe(
      finalize(() => {
        this.activeRequests--
        if (this.activeRequests === 0) {
          // There are no ongoing requests
          if (this.timeout === null) {
            // The minimum loading duration has been exceeded
            // End loading
            this.store.dispatch(setIsLoading({ payload: false }))
          }
        }
      }),
    )
  }

  private shouldSetIsLoading(request: HttpRequest<unknown>) {
    const ignoredRequests = new RegExp(
      '^.+\\.(jpe?g|png|gif|bmp|svg)$|/comments|/assets|projects/.+/insights|v1.2(?!.*/segment)(/[^ ]*)?|ads',
    )
    return !ignoredRequests.test(request.url)
  }
}
