import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, throwError } from 'rxjs'
import { catchError, filter, switchMap, take } from 'rxjs/operators'

import { JwtService, UserService } from '~core/services'
import { SnackbarService } from '~core/services/ui/snackbar.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private jwtService: JwtService,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private router: Router,
  ) {}

  addAuthenticationToken(request) {
    // Get access token from Local Storage
    const accessToken = this.jwtService.getToken()

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
        ContentType: 'application/json',
      },
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes('auth/refresh') ||
          request.url.includes('login') ||
          request.url.includes('register') ||
          request.url.includes('reset') ||
          request.url.includes('forgot')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page
          if (request.url.includes('auth/refresh')) {
            this.snackbarService.open('Session expired! Please sign in again.')
            // localStorage.removeItem(TOKEN_NAME);
            this.router.navigate(['login'])
          }

          return throwError(() => error)
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return throwError(() => error)
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request))),
          )
        } else {
          this.refreshTokenInProgress = true

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null)

          // Call auth.refresh(this is an Observable that will be returned)
          return this.userService.refresh().pipe(
            switchMap((token: any) => {
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false
              this.refreshTokenSubject.next(token)

              return next.handle(this.addAuthenticationToken(request))
            }),
            catchError((error: HttpErrorResponse) => {
              this.refreshTokenInProgress = false
              // localStorage.removeItem(TOKEN_NAME);
              this.router.navigate(['login'])
              return throwError(() => error)
            }),
          )
        }
      }),
    )
  }
}
