import { Component, OnInit } from '@angular/core'
import { TranslocoModule } from '@ngneat/transloco'
import { LogoComponent } from 'src/app/common/components/logo/logo.component'

@Component({
  selector: 'sb-info-section',
  templateUrl: './info-section.component.html',
  styleUrls: ['./info-section.component.scss'],
  standalone: true,
  imports: [LogoComponent, TranslocoModule],
})
export class InfoSectionComponent {}
