import { NgClass } from '@angular/common'
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-spinner',
  template: `<div class="spinner-border text-primary mx-auto" [ngClass]="{ 'd-block': displayBlock }"></div>`,
  styles: ['.spinner-border { border-width: 0.13rem }'],
  standalone: true,
  imports: [NgClass],
})
export class SpinnerComponent {
  @Input() displayBlock = true
}
